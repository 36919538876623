.iq3-detail-panel {
  display: flex;
  flex-direction: column;
}

.resizeHandleOuter {
  position: relative;
  outline: none;
  --background-color: #979694;
  margin-left: -5px;
  width: 105%;
}

.resizeHandleInner {
  position: absolute;
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
  border-radius: 0.25em;
  background-color: #979694;
  transition: background-color 0.2s linear;
  height: 2px;
}

.iq3-detail-split-icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
  display: none;
}

.deleteFooter {
  display: flex;
  justify-content: space-between;
}
